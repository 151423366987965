import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FiShare2 } from 'react-icons/fi';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { useStateFromProp } from '~hooks/common';
import { useGet } from '~hooks/useQuery';
import { useOpenChat } from '~hooks/useOpenChat';
import { nl2br, renderFirstName } from '~helpers/common';

import Modal from '~components/modal';
import Avatar from '~components/avatar';
import ReviewsList from '~components/review/list';
import Stars from '~components/stars';
import LastOnline from '~components/last-online';
import VerificationBadge from '~components/verification/badge';
import Button from '~components/button';
import Share from '~components/share';
import Favorite from '~components/favorite';

import '../../style.scss';
import '../style.scss';

const ProfileModal = ({
  personId,
  locationId,
  firstName,
  profilePicture,
  personalNote,
  score,
  price,
  experience,
  lastLogin,
  isOnline,
  registerDate,
  verification,
  isOpened,
  published,
  onToggle = () => {},
  onFavorite = () => {},
}) => {
  if (!isOpened) {
    return null;
  }

  const intl = useIntl();

  const domainConfig = useSelector((state) => state.domainConfig);

  const [isModalOpened, setModalOpen] = useStateFromProp(isOpened, onToggle);
  const [isShareOpened, setShareOpen] = useState(false);
  const fName = renderFirstName(firstName);
  const { openChat } = useOpenChat({ personId, accountType: 'worker', locationId });
  const { data: reviews, isLoading: isLoadingReviews } = useGet(
    {
      name: `reviews`,
      requiresAuthentication: false,
      shouldExecute: !!personId && isModalOpened,
      mockFileName: 'reviews/get-by-receiver',
      params: {
        'receiver-id': personId,
        // limit: 10,
        // offset: 0,
      },
    },
    [personId]
  );

  const handleChat = () => {
    openChat();
  };

  return (
    <Modal.Wrapper isOpened={isModalOpened} onClose={() => setModalOpen()} className="c-profile c-profile--modal">
      <Button className="c-profile__share" onClick={() => setShareOpen(true)}>
        <FiShare2 />
      </Button>
      <div className="c-profile__picture">
        <Avatar profilePicture={profilePicture} firstName={fName} size="large" />
      </div>
      <Modal.Content>
        <div className="c-profile__picture--mobile">
          <Avatar profilePicture={profilePicture} firstName={fName} size="large" />
        </div>
        <div className="c-profile__header">
          <div className="c-profile__name l-headline--secondary notranslate">
            {fName}
            <VerificationBadge isVerified />
          </div>
          <div className="c-profile__score">
            <Stars value={score?.score || 4} />
            {/* <Stars value={score?.score} disabled={!score || !score.score} /> */}
            {score && score.votes > 0 ? <div className="c-profile__votes">({score.votes})</div> : null}
          </div>
          {/* <div className="c-profile__price">
            {intl.formatMessage(
              { id: 'containers.profile.worker.price', defaultMessage: 'Hourly rate {amount}' },
              {
                amount: intl.formatNumber(price.amount / 100, {
                  style: 'currency',
                  currency: price.currency,
                }),
              }
            )}
          </div> */}
          <div className="c-profile__top-buttons">
            <Button onClick={handleChat}>
              {intl.formatMessage({ id: 'containers.profile.worker.buttons-top.chat', defaultMessage: 'Chat' })}
            </Button>
            <Favorite accountType="worker" personId={personId} onChange={onFavorite} />
          </div>
        </div>
        {/* <div className="c-profile__features"> */}
          {/* temp_hide */}
          {/* <div className="c-profile__feature">
            <div className="c-profile__key">
              {intl.formatMessage({
                id: 'containers.profile.worker.active-since.label',
                defaultMessage: 'Active since',
              })}
            </div>
            <div className="c-profile__value">
              {registerDate
                ? intl.formatDate(moment.unix(registerDate).toDate(), {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })
                : '-'}
            </div>
          </div> */}
          {/* <div className="c-profile__feature">
            <div className="c-profile__key">
              {intl.formatMessage({ id: 'containers.profile.worker.experience.label', defaultMessage: 'Experience' })}
            </div>
            <div className="c-profile__value">
              {intl.formatMessage(
                {
                  id: 'containers.profile.worker.experience.value',
                  defaultMessage: '{experience, plural, =1 {# appointment} other {# appointments}}',
                },
                { experience }
              )}
            </div>
          </div> */}
          {/* temp_hide */}
          {/* <div className="c-profile__feature">
            <div className="c-profile__key">
              {intl.formatMessage({ id: 'containers.profile.worker.last-online.label', defaultMessage: 'Last online' })}
            </div>
            <div className="c-profile__value">
              <LastOnline lastOnline={lastLogin} isOnline={isOnline} isPublished={published} />
            </div>
          </div> */}
        {/* </div> */}
        <div className="c-profile__about">
          <div className="l-headline--tertiary">
            {intl.formatMessage({ id: 'containers.profile.worker.about', defaultMessage: 'About' })}
          </div>
          <p>{personalNote ? nl2br(personalNote) : '-'}</p>
        </div>
          <ReviewsList
            isLoading={isLoadingReviews}
            reviews={reviews?.data}
            className='c-profile__reviews'
            headline={intl.formatMessage({ id: 'containers.profile.worker.reviews', defaultMessage: 'Customer reviews' })}
          />
        <div className="c-profile__footer">
          <div className="c-profile__bottom-buttons">
            <Button onClick={handleChat}>
              {intl.formatMessage({ id: 'containers.profile.worker.buttons-bottom.chat', defaultMessage: 'Chat' })}
            </Button>
            <Favorite accountType="worker" personId={personId} onChange={onFavorite} />
          </div>
        </div>
      </Modal.Content>
      <Share
        sharedUrl={`${domainConfig.protocol}://${domainConfig.domain}/worker/${personId}`}
        title={intl.formatMessage(
          {
            id: 'containers.profile.seo.worker.share-title',
            defaultMessage: '{firstName} cleaner profile - #website',
          },
          {
            firstName: fName,
          }
        )}
        isOpened={isShareOpened}
        onToggle={() => setShareOpen(false)}
      />
    </Modal.Wrapper>
  );
};

export default ProfileModal;
